<template>
    <div class="misc-wrapper m-0">
        <b-link class="brand-logo" :to="{path:'/'}" >
        <img src="@/assets/images/logo/logo.svg" alt="logo" height="60" />
        <!-- <h2 class="brand-text text-primary ml-1">
          Bubblin
        </h2> -->
      </b-link>
  
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Vous n'êtes pas autorisé! 🔐
          </h2>
          <p class="mb-2">
            Vous n'êtes pas autorisé à accéder à cette page.
          </p>
          <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Retour à la page d'accueil
        </b-button>
          <b-img
            fluid
            :src="imgUrl"
            alt="Not authorized page"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { BLink, BImg, BButton } from 'bootstrap-vue'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import store from '@/store/index'
  
  export default {
    components: {
      BLink, BImg, BButton, VuexyLogo,
    },
    data() {
      return {
        downImg: require('@/assets/images/pages/not-authorized.svg'),
      }
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
          return this.downImg
        }
        return this.downImg
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
  </style>
  